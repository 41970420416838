@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
body {
  font-family: 'Poppins', sans-serif !important;
}
h1, h2, h3, h4, span, small, p, label {
  color: #3b4144;
}
.nav-item, .nav-link, .logo {
  cursor: pointer;
}

.logo {
  width: 70px;
  height: 30px;
  margin-right: 30px;
}
li.nav-item {
  margin: 0 12px;
}
a.nav-link {
  color: #000;
}
/* .nav-btn {
  margin-left: 20px;
} */

.search input{
  border: 1px solid rgb(245, 246, 247);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 8px 64px 8px 16px;
  color: rgb(59, 65, 68);
  width: 100%;
  height: 60px;
  transition: width 0.35s ease 0s;
  display: inline-block;
  appearance: none;
  font-size: 20px;
  line-height: 1.1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search input:focus {
  outline: 0;
}

.search {
  width: 100%;
  max-width: 568px;
  padding: 0px 24px;
  text-align: left;
  margin: 0px auto;
}
.search-button {
  width: 60px;
  background: rgb(217, 60, 35);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  transition: background-color 0.15s ease 0s;
  cursor: pointer;
}
.search-icon {
  width: 100%;
}
.banner {
  background-image: url(./assets/images/banner-img.jpg);
  background-size: cover;
  border-radius: 10px;
  padding-top: 150px;
  padding-bottom: 150px;
  background-position: center;
}
.banner-text h1{
  color: #fff;
  line-height: 1.14;
  font-size: 50px;
}
.banner-tabs {
  width: 262px;
  margin: 0 auto;
  background-color: rgba(59, 65, 68, 0.6);
    border-radius: 8px;
}
.banner-tabs .nav-item{
  margin: 0 !important;
}
.banner-tabs .nav-link.active {
  background-color: #fff !important;
  color: rgb(0, 120, 130) !important;
}
.banner-tabs .nav-link {
  color: #fff;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 700;
}
.sub-heading {
  font-weight: bold;
    font-size: 38px;
    line-height: 1.05;
    color: rgb(59, 65, 68);
}
.help-img img {
  width: 118px;
}
.help-text button {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 120, 130);
  padding: 6px 26px;
  border-radius: 7px;
  transition: .4s ease;
  border: 1px solid rgb(0, 120, 130);
}

.help-text button:hover {
  transition: .4s ease;
  border: 1px solid rgb(0, 120, 130);
  background-color: #fff;
  color: rgb(0, 120, 130);
}
.check-neighborhood {
  margin-top: 76px;
}
.discover img{
  width: 28px;
}
.discover svg {
  width: 28px;
}
.discover-place {
  margin-top: 90px;
  margin-bottom: 70px;
}
.realestate-market ul {
  list-style: none;
  padding: 0;
  color: rgb(59, 65, 68);
  font-size: 13px;
}
.realestate-market ul li{
  padding-bottom: 5px;
}
.realestate-market h5 {
  color: rgb(59, 65, 68);
  font-size: 18px;
  margin-bottom: 18px;
}
.text-section p {
  font-size: 13px;
}
.newly-listed .card {
  position: relative;
}
.card-address {
  color: #3b4144;
  font-weight: 300;
  font-size: 16px;
}
.state-details img {
  width: 20px;
}
.state-details span {
  font-size: 15px;
  font-weight: 300;
}
.fs-7 {
  font-size: 11px;
}
.tag {
  position: absolute;
  top: 7px;
  left: 22px;
  margin-right: 4px;
  text-transform: uppercase;
  background-color: rgb(255, 255, 255);
  color: rgb(5, 34, 134);
  font-size: 12px;
  line-height: 1.33;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: bold;
}
.newly-listed .card .state-img {
  height: 160px;
  overflow: hidden;
}
.newly-listed .card .state-img img {
  width: 100%;
  height: 100%;
  transition: .3s ease;
}
.card:focus-visible {
  outline: none;
}
.card {
  cursor: pointer;
}
.card:hover .state-img img {
  transform: scale(1.2);
  transition: .3s ease;
}
/* .explore-slider .card {
  width: 280px;
  height: 400px;
}
.explore-slider .card .state-img-single {
  width: 100%;
  height: 100%;
}
.explore-slider .card .state-img-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.state-full-height {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
}
.explore-slider img {
  width: 100%;
  height: 100%;
}
.state-half-height {
  width: 100%;
  height: 192px;
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;
}
.bg-grey {
  background: rgb(107, 119, 128);
}
.bg-green {
  background: rgb(20, 66, 37);
}

.state-title {
  position: absolute;
  top: 10px;
  left: 10px;
}
.state-title h6{
  color: #fff;
  font-size: 20px;
  text-shadow: rgb(0 0 0) 0px 1px 5px;
}
.state-shadow {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%);
  height: 100%;
}
.state-btn {
  position: absolute;
  bottom: 12px;
  left: 10px;
  background: #ffffffb8;
  padding: 3px 12px;
  border-radius: 8px;
}
.back-to-search {
  color: rgb(0, 120, 130);
  font-size: 14px;
  font-weight: 500;
}
.breadcrumbs {
  font-size: 14px;
  color: rgb(0, 120, 130);
}
.single-property-detail h4 {
  font-size: 28px;
}
.single-property-detail p {
  font-size: 14px;
}
.single-property-detail .state-details span {
  font-size: 16px;
}
.single-property-nav .nav-link.active {
  border: 1px solid;
  color: rgb(0, 120, 130) !important;
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(232, 233, 234);
  margin-bottom: 16px;
  box-shadow: rgb(59 65 68 / 40%) 0px 8px 20px -15px !important;
}
.single-property-nav .nav-link, .single-property-nav .nav-link:hover {
  color: #000;
}
.single-property-nav .nav-link.active:focus-visible,.single-property-nav .nav-link.active:focus-visible, .single-property-nav .nav-item:focus-visible {
  outline: none !important;
}
.single-local-information p{
  font-size: 14px;
}
.highlight-list img{
  width: 20px;
}
.highlight-row {
  border: 1px solid #e8e9ea;
  border-radius: 6px;
  padding: 16px;
}
.price-history-table {
  border: 1px solid;
}
.search-properties .single-search-property {
  position: relative;
}
.search-properties .state-img img {
  width: 100%;
}
.search-properties .state-img {
  overflow: hidden;
}
.search-map {
  height: 84vh;
  border-radius: 10px;
  overflow: hidden;
  position: fixed;
  width: 40%;
}
.search-properties .tag {
  left: 10px;
}
.price-dropdown .dropdown-menu {
  width: 300px;
  padding: 8px;
}
.price-range-wrapper select {
  width: 45%;
}
.near-transit-dropdown .dropdown-menu{
  width: 250px;
}
.filters button.btn {
  margin-right: 12px;
  color: rgb(59, 65, 68);
  background-color: rgb(255, 255, 255);
  border-color: rgb(205, 209, 212);
  font-weight: 500;
  padding: 8px 19px;
  transition: .3s ease;
}
.filters button.btn:hover {
  background-color: rgb(205, 209, 212);
  transition: .3s ease;
}
.filters label {
  font-size: 15px;
  font-weight: 300;
}
.filters .dropdown-menu {
  border: 0px;
}
.filters,.single-property-detail {
  flex-flow: wrap;
}
.rental-dropdown .dropdown-menu {
  width: 250px;
}
.header {
  border-bottom: 1px solid rgb(232, 233, 234);
}
.save-search-btn button {
  color: rgb(0, 120, 130) !important;
  background-color: rgb(255, 255, 255) !important;
  border-color: rgb(0, 173, 187) !important;
  transition: .3s ease;
}
.save-search-btn button:hover {
  color: rgb(0, 120, 130) !important;
  background-color: rgb(224, 247, 248) !important;
  border-color: rgb(0, 120, 130) !important;
  transition: .3s ease;
}
.custom-drop:hover .dropdown-content {
  display: block !important;
}
.custom-drop:hover {
  background-color: #007882;
}
.custom-drop:hover a {
  color: #fff !important;
  transition: 0s;
}
.custom-drop {
  padding: 0px 16px;
  border-radius: 5px;
  transition: 0s;
  position: relative;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  top: 17px;
  left: 0;
}
.dropdown-content h6 {
  padding: 12px 0px 0px 12px;
}
.dropdown-content p {
  font-size: 14px;
  font-weight: 300;
  padding: 8px 12px;
}
.dropdown-content p:hover {
  cursor: pointer;
  background-color: #e8e9ea;
}
.header-searchbar .search {
  margin-top: 0 !important;
  padding: 0;
}
.header-searchbar input,.header-searchbar .search-button {
  height: 41px;
  font-size: 16px;
}
.header-searchbar .search-button {
  width: 50px;
  padding: 10px;
}
.header-searchbar input {
  background-color: rgb(245, 246, 247);
  width: 360px;
}
#offcanvasNavbar {
  background: rgb(59, 65, 68);
  color: #fff;
}
.offcanvas-body a:after {
  position: absolute;
  right: 0;
  color: #fff;
}
.offcanvas-body a.nav-link{
  color: #fff;
}
.offcanvas-body .dropdown-menu {
  background: transparent;
  border: 0;
}
.offcanvas-body .dropdown-menu a {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  padding: 8px 0;
}
.offcanvas-body .dropdown-menu a:hover {
  background-color: transparent;
}
.offcanvas-para {
  margin-bottom: 5px;
  color: #ffffff9c;
  font-size: 14px;
  font-weight: 300;
}
.canvas-close-btn {
  background-color: transparent;
  border: 0px;
}
.canvas-close-btn svg{
  fill: #fff;
  width: 26px;
  height: 26px;
}
.navbar-toggler {
  box-shadow: none !important;
}
.schedule-btn {
  color: rgb(255, 255, 255);
  background-color: rgb(217, 60, 35);
  border-color: transparent;
  outline: none;
  border: 0;
  padding: 9px 0px;
  border-radius: 6px;
  margin-bottom: 15px;
  width: 228px;
}
.request-info {
  color: rgb(0, 120, 130);
  background-color: rgb(255, 255, 255);
  outline: none;
  border: 1px solid rgb(0, 173, 187);
  padding: 9px 0px;
  border-radius: 6px;
  margin-bottom: 15px;
  width: 228px;
}
.forsale-wrapper img {
  width: 22px;
}


@media screen and (max-width: 1355px) {
  .header-searchbar input{
    width: 246px;
  }
}
@media screen and (max-width: 1233px) {
  .header-searchbar input {
    width: 153px;
  }
  .custom-drop{
    padding: 0px 7px;
  }
}
@media screen and (max-width: 1085px) {
  .header .main-nav {
    display: none !important;
  }
  .header button.btn.btn-outline-success.nav-btn {
    display: none;
  }
}
@media screen and (max-width: 991.98px) {
  .search-map{
    width: 47%;
  }
}
@media screen and (max-width: 480px) {
  .banner-text h1{
    font-size: 36px;
  }
  .discover-place{
    display: none;
  }
}



